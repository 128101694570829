import React from 'react';
import { Link } from 'gatsby';

import { Button as RebassButton } from 'rebass/emotion';

import { standardButtonProps } from '../utils/style';

const ButtonLink = RebassButton.withComponent(Link);

const Button = ({ children, to, className }) => {
  return <ButtonLink children={children} to={to} className={className} {...standardButtonProps} />;
};

export default Button;
