import React from 'react';
import { Link } from 'gatsby';

import { Row, Column, Toolbar, NavLink as RebassNavLink, Text } from 'rebass/emotion';
import styled, { css } from 'react-emotion';
import withProps from 'recompose/withProps';

import MobileMenu from './MobileMenu';
import LogoText from '../LogoText';
import Button from '../Button';
import theme from '../../theme';
import style, { animatedUnderline, underline } from '../../utils/style';

const flipDisplay = css`
  margin-left: auto;
  ${style.mediaQueries.maxTablet} {
    && {
      display: none;
    }
  }
`;

const columnStyle = css({
  paddingLeft: '8px',
  maxWidth: '1200px',
  margin: '0 auto',
});

const LinkText = styled(Text)(
  props => ({
    fontFamily: theme.fonts.display,
    fontWeight: 400,
    color: 'white',
    transition: 'border-color 0.1s',
    borderWidth: '0px',
    textTransform: 'capitalize',
    '&:hover': animatedUnderline,
  }),
  animatedUnderline,
  props => props.isActive && underline
);

const MenuLink = ({ children, toggleModal }) => {
  return (
    <RebassNavLink onClick={toggleModal} ml="auto" mr={[-3, 0]} fontSize={[3, 3]}>
      <LinkText pt={['15px', '20px']} pb={['5px']} px={1}>
        {children}
      </LinkText>
    </RebassNavLink>
  );
};

const NavLink = RebassNavLink.withComponent(Link);
const StyledLogoLink = withProps({
  pl: [4],
  ml: [-3],
  fontSize: [5, '40px', 6],
  css: { display: 'block', fontFamily: theme.fonts.display, fontWeight: 400, color: 'white' },
})(NavLink);

const rowStyles = {
  default: {
    py: ['1em'],
    mx: [0],
    bg: '#4CCCFF',
    css: { flexShrink: '0' },
  },
};

class Header extends React.Component {
  state = {
    isOpen: false,
  };

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const toggleModal = this.props.toggleModal;
    const rowStyle = rowStyles.default;

    return (
      <Row {...rowStyle}>
        <Column className={columnStyle}>
          <Toolbar bg="transparent" mr={2}>
            <StyledLogoLink to="/">
              <LogoText />
            </StyledLogoLink>
            <MobileMenu
              handleClick={this.handleClick}
              isOpen={this.state.isOpen}
              toggleModal={toggleModal}
              LinkText={LinkText}
              LogoText={LogoText}
            />
            <div className={flipDisplay}>
              <MenuLink children={'Docs'} toggleModal={toggleModal} />
              <MenuLink children={'Pricing'} toggleModal={toggleModal} />
              <MenuLink children={'About'} toggleModal={toggleModal} />
              <MenuLink children={'Sign In'} toggleModal={toggleModal} />
              <RebassNavLink is={'div'} children={<Button children="Try Now" to="/app" />} mr={[-3, 0]} />
            </div>
          </Toolbar>
        </Column>
      </Row>
    );
  }
}

export default Header;
