import React from 'react';
import { Link } from 'gatsby';

import { Row, Column, Toolbar, NavLink as RebassNavLink, Text } from 'rebass/emotion';
import styled, { css } from 'react-emotion';
import withProps from 'recompose/withProps';

import LogoText from './LogoText';
import theme from '../theme';
import style, { animatedUnderline, underline } from '../utils/style';

const changeOrientation = css`
  ${style.mediaQueries.maxTablet} {
    && {
      flex-direction: column;
    }
  }
`;

const columnStyle = css({
  paddingLeft: '8px',
  maxWidth: '1200px',
  margin: '0 auto',
});

const StyledText = styled(Text)(
  props => ({
    fontFamily: theme.fonts.display,
    fontWeight: 400,
    color: 'white',
    transition: 'border-color 0.1s',
    borderWidth: '0px',
    textTransform: 'capitalize',
    '&:hover': animatedUnderline,
  }),
  animatedUnderline,
  props => props.isActive && underline
);

const MenuLink = ({ children, toggleModal }) => {
  return (
    <RebassNavLink
      onClick={toggleModal}
      ml={[-4, 'auto']}
      mr={[-3, 0]}
      fontSize={[3, 3]}
      css={{ alignSelf: 'center' }}>
      <StyledText pt={[0, '20px']} pb={[0, '5px']} px={1}>
        {children}
      </StyledText>
    </RebassNavLink>
  );
};

const NavLink = RebassNavLink.withComponent(Link);
const StyledLogoLink = withProps({
  w: [1 / 2, 1],
  pl: [3, 4],
  ml: [-4, -3],
  color: 'white',
  fontSize: [5, '40px', 6],
  css: { alignSelf: 'center', fontFamily: theme.fonts.display, fontWeight: 400 },
})(NavLink);

const rowStyles = {
  default: {
    py: ['1em'],
    mx: [0],
    mt: 4,
    bg: '#4CCCFF',
    css: { flexShrink: '0' },
  },
};

const Footer = ({ toggleModal }) => {
  const rowStyle = { ...rowStyles.default };
  return (
    <Row {...rowStyle}>
      <Column className={columnStyle}>
        <Toolbar className={changeOrientation} bg="transparent">
          <StyledLogoLink to="/">
            <LogoText />
          </StyledLogoLink>
          <MenuLink children={'Pricing'} toggleModal={toggleModal} />
          <MenuLink children={'About'} toggleModal={toggleModal} />
          <MenuLink children={'Privacy'} toggleModal={toggleModal} />
          <MenuLink children={'Terms'} toggleModal={toggleModal} />
        </Toolbar>
      </Column>
    </Row>
  );
};

export default Footer;
