import React, { Component, Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';
import { Provider, Row, Column } from 'rebass/emotion';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../theme';
import 'typeface-fira-sans';
import '../css/global.css';
import '../css/prism-solarizedlight.css';

class Layout extends Component {
  render() {
    const { children, location, toggleModal } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: data.site.siteMetadata.description },
                {
                  name: 'keywords',
                  content: 'experience, human, obvious, ordinary, meaningful, fulfillment',
                },
              ]}
            />
            <Provider theme={theme}>
              <div css={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Header
                  toggleModal={toggleModal}
                  siteTitle={data.site.siteMetadata.title}
                  location={location}
                />
                <Row mt={[0, 6]} mx={0} css={{ flex: '1 0 auto' }}>
                  <Column css={{ label: 'outside-column', maxWidth: '1200px', margin: '0 auto' }} mb={0}>
                    {children}
                  </Column>
                </Row>
                <Footer toggleModal={toggleModal} />
              </div>
            </Provider>
          </Fragment>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  data: PropTypes.object,
};

export default Layout;
